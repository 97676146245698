import {
  Box,
  Flex,
  Icon,
  Text,
  useDisclosure,
  Heading,
  SimpleGrid,
  IconButton,
  Wrap,
  Center,
  GridItem,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import {
  SDButton,
  SDFileUpload,
  SDInput,
  SDInputErrorMessage,
  SDSelect,
  TabSwitch,
  WarningModal,
  WhiteBox,
} from "components/global";
import { FieldArray, FormikValues, useFormikContext } from "formik";
import { BsPlusLg } from "react-icons/bs";
import SDGooglePlacesAutoComplete from "components/global/Select/SDGooglePlacesAutoComplete";
import { PiXCircleBold } from "react-icons/pi";
import { formatDateToFormField } from "utils/utilFunctions";
import { DELIVERY_OPTIONS, PARCEL_TYPE_OPTIONS } from "utils/constants";
import {
  selectInterStatePricing,
  selectLastMilePricing,
} from "redux/slices/adminSlices/settingsSlice";
import { useAppSelector } from "redux/store";
import { useSearchParams } from "react-router-dom";
import { FaDotCircle, FaRegCircle } from "react-icons/fa";
import { IOrderType, IUserType } from "types/global";

interface Props {
  setPage: Dispatch<SetStateAction<number>>;
  role: IUserType;
}

function PickupDetailsForm({ role, setPage }: Props) {
  const [searchParams] = useSearchParams();
  const orderType = useMemo(
    () => searchParams.get("type") || "lastMile",
    [searchParams]
  ) as IOrderType;

  const lastMilePricing = useAppSelector(selectLastMilePricing);
  const interStatePricing = useAppSelector(selectInterStatePricing);

  const deleteParcelDisclosure = useDisclosure();
  const [parcelToDeleteIndex, setParcelToDeleteIndex] = useState<number | null>(null);

  const { values, errors, touched, handleBlur, handleChange, setFieldValue, isValid, dirty } =
    useFormikContext<FormikValues>();

  function handleDeleteParcel() {
    if (parcelToDeleteIndex >= 0) {
      const newArr = values.parcelDetails.filter((_, index) => index !== parcelToDeleteIndex);

      setFieldValue("parcelDetails", newArr);
    }

    deleteParcelDisclosure.onClose();
  }

  return (
    <WhiteBox
      py={role === "provider" ? [8, 8, 8, 10] : 0}
      px={role === "provider" ? [6, 6, 8, 12] : 0}
    >
      <Box maxW="1000px" mx={role === "provider" && "auto"}>
        {role === "provider" && (
          <Flex align="center" justify="space-between" gap={1} mb={8}>
            <Heading color="neutral.500" fontWeight={600} fontSize="20px">
              Add pick-up details
            </Heading>

            {/* <FormControl as={Flex} align="center" w="fit-content">
              <FormLabel htmlFor="email-alerts" mb={0} color="neutral.200">
                Auto Assign To Courier
              </FormLabel>
              <Switch id="email-alerts" colorScheme="primary" />
            </FormControl> */}
          </Flex>
        )}

        <Box>
          {role === "provider" ? (
            <Text color="neutral.500">
              Select an option for {/* {orderType === "lastMile" ? "last mile" : "inter state"} */}
              delivery type{" "}
              <Text as="span" color="neutral.200" fontSize="14px">
                (to select more options go to settings then click &lt;pricing&gt; and click &lt;add
                pricing&gt; to create more options)
              </Text>
            </Text>
          ) : (
            <Text color="neutral.500">
              Select {/* {orderType === "lastMile" ? "last mile" : "inter state"} */} delivery type
            </Text>
          )}

          <Wrap mt={4}>
            {(orderType === "lastMile" ? lastMilePricing : interStatePricing).map((item, key) => (
              <Center
                flexDir="column"
                gap={2}
                px="14px"
                py="10px"
                maxW="100px"
                w="full"
                key={key}
                border="1px solid #0600891A"
                borderRadius="4px"
                cursor="pointer"
                transition="all 200ms ease"
                position="relative"
                onClick={() => setFieldValue("pricingId", item?._id)}
                _hover={{ bgColor: "#0600891A" }}
              >
                {/* Floating Radio Icon */}
                <Icon
                  as={values.pricingId === item?._id ? FaDotCircle : FaRegCircle}
                  boxSize={4}
                  pos="absolute"
                  top="5px"
                  right="5px"
                  color="primary.500"
                />

                {/* Vehicle Icon */}
                <Icon
                  as={DELIVERY_OPTIONS.find(i => i.value === item?.deliveryType)?.icon}
                  boxSize={8}
                />

                <Text fontSize="10px" fontWeight={300} textAlign="center">
                  {item?.name}
                </Text>
              </Center>
            ))}
          </Wrap>
          {errors.pricingId && <SDInputErrorMessage error={errors.pricingId} />}
        </Box>

        {role === "client" && (
          <Box mt={12}>
            <TabSwitch
              tabs={[
                { title: "Pick-up details", onClick: () => setPage(1) },
                { title: "Drop-off details", onClick: () => setPage(2) },
              ]}
              disableClick={!dirty || !isValid}
              defaultTab={0}
            />
          </Box>
        )}

        <SimpleGrid columns={[1, 1, 2]} columnGap={6} mt={8}>
          <SDInput
            id="customerName"
            type="text"
            label="Customer’s Name"
            placeholder="Enter full name"
            value={values.customerName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.customerName && errors.customerName}
          />

          <SDInput
            id="customerPhoneNumber"
            type="tel"
            label="Customer’s Phone Number"
            placeholder="Enter phone number"
            value={values.customerPhoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.customerPhoneNumber && errors.customerPhoneNumber}
          />

          <SDGooglePlacesAutoComplete
            id="pickupLocation"
            label="Pick-Up Location"
            placeholder="Search for a location"
            value={values.pickupLocation}
            onChange={setFieldValue}
            onBlur={handleBlur}
            error={touched.pickupLocation && errors.pickupLocation}
          />

          <SDInput
            id="customerPickupCode"
            type="text"
            label="Customer’s Pick-Up Code"
            placeholder=""
            defaultValue={values.customerPickupCode}
            onBlur={handleBlur}
            error={touched.customerPickupCode && errors.customerPickupCode}
            isReadOnly={true}
          />

          <GridItem mt={8} mb={2} colSpan={[1, 1, 2]}>
            <Heading color="neutral.500" fontWeight={600} fontSize="18px">
              Schedule delivery (optional)
            </Heading>
          </GridItem>

          <SDInput
            id="scheduleForPickupDate"
            type="date"
            label="Pick-Up Date"
            placeholder="Enter a date"
            value={values.scheduleForPickupDate}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.scheduleForPickupDate && errors.scheduleForPickupDate}
            min={formatDateToFormField(Date.now())}
          />

          <SDInput
            id="scheduleForPickupTime"
            type="time"
            label="Pick-Up Time"
            placeholder="Enter a time"
            value={values.scheduleForPickupTime}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.scheduleForPickupTime && errors.scheduleForPickupTime}
          />
        </SimpleGrid>

        <SDFileUpload
          label=""
          name="image"
          onFileChange={setFieldValue}
          value={values.image}
          error={touched.image && errors.image}
          uploadText="Upload the package image"
          hidePreview={true}
          uploadContainerProps={{ minH: "120px" }}
        />

        <Box mt={8}>
          <Heading color="neutral.500" fontWeight={600} fontSize="18px" mb={4}>
            Parcel details
          </Heading>

          <FieldArray name="parcelDetails">
            {({ push }) => (
              <>
                {values.parcelDetails.map((item, key) => (
                  <SimpleGrid
                    key={key}
                    columns={{ base: 1, sm: 2, md: 4 }}
                    columnGap={6}
                    px={2}
                    my={2}
                    border="1px solid #CBD5E0"
                    borderRadius="10px"
                    position="relative"
                  >
                    <SDInput
                      id={`parcelDetails.${key}.content`}
                      type="text"
                      label={`Parcel content ${key + 1}`}
                      placeholder="Mac book pro"
                      value={values?.parcelDetails[key]?.content}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.parcelDetails?.[key]?.content &&
                        errors.parcelDetails?.[key]?.content
                      }
                      containerProps={{ gridColumn: { sm: "1/3" } }}
                    />

                    <SDInput
                      id={`parcelDetails.${key}.noOfContents`}
                      type="number"
                      label="No of contents"
                      placeholder="4"
                      value={values?.parcelDetails[key]?.noOfContents}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.parcelDetails?.[key]?.noOfContents &&
                        errors.parcelDetails?.[key]?.noOfContents
                      }
                    />

                    <SDSelect
                      id={`parcelDetails.${key}.parcelType`}
                      label="Category"
                      placeholder="Select an option"
                      options={PARCEL_TYPE_OPTIONS}
                      value={values?.parcelDetails[key]?.parcelType}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      error={
                        touched.parcelDetails?.[key]?.parcelType &&
                        errors.parcelDetails?.[key]?.parcelType
                      }
                    />

                    {values.parcelDetails.length > 1 && (
                      <IconButton
                        variant="ghost"
                        colorScheme="error"
                        onClick={() => {
                          setParcelToDeleteIndex(key);
                          deleteParcelDisclosure.onOpen();
                        }}
                        aria-label="Delete Parcel"
                        icon={<PiXCircleBold />}
                        borderRadius="full"
                        position="absolute"
                        top={-5}
                        right={-5}
                      />
                    )}
                  </SimpleGrid>
                ))}

                <SDButton
                  leftIcon={<BsPlusLg />}
                  colorScheme={role === "provider" ? "facebook" : "primary"}
                  variant="link"
                  size="sm"
                  p={0}
                  ps={1}
                  pe={2}
                  mt="10px"
                  onClick={() => push({ content: "", noOfContents: "", parcelType: null })}
                >
                  Add another Parcel
                </SDButton>
              </>
            )}
          </FieldArray>

          <Box my={4}>
            <Text fontSize="14px" fontWeight={700} color="neutral.500" mb={1}>
              Total number of parcels
            </Text>

            <Flex
              py={2}
              px={4}
              align="center"
              justify="space-between"
              borderRadius="10px"
              maxW="170px"
              border="1px solid #CBD5E0"
            >
              <Text>{values.parcelDetails.length}</Text>
              <Icon as={MdKeyboardArrowRight} color="#292D32" />
            </Flex>
          </Box>
        </Box>

        <Flex mt={8} justify="flex-end">
          <SDButton colorScheme="primary" type="submit">
            Next
          </SDButton>
        </Flex>

        <WarningModal
          isOpen={deleteParcelDisclosure.isOpen}
          onClose={deleteParcelDisclosure.onClose}
          message="Do you want to delete this parcel?"
          subMessage={values.parcelDetails[parcelToDeleteIndex]?.content}
          proceedBtnText="Delete"
          onBtnClick={() => handleDeleteParcel()}
        />
      </Box>
    </WhiteBox>
  );
}

export default PickupDetailsForm;
