import { Flex, Heading } from "@chakra-ui/react";
// @ts-ignore
import { BeatLoader } from "react-spinners";

interface Props {
  message?: string;
}

function MainLoader({ message }: Props) {
  return (
    <Flex
      flexDir="column"
      align="center"
      justify="center"
      gap={4}
      w="100vw"
      h="100vh"
      bgColor="rgba(0, 0, 0, .3)"
      position="fixed"
      top={0}
      left={0}
      zIndex={9999}
    >
      <BeatLoader loading={true} color="#0a2d79" size="30px" />

      <Heading fontWeight={400} fontSize="md">
        {message}
      </Heading>
    </Flex>
  );
}

export default MainLoader;
