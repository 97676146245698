import {
  Box,
  Center,
  Checkbox,
  Flex,
  Heading,
  Icon,
  IconButton,
  Text,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import {
  SDButton,
  SDInput,
  SDInputErrorMessage,
  SDVerticalStepper,
  WarningModal,
  WhiteBox,
} from "components/global";
import SDGooglePlacesAutoComplete from "components/global/Select/SDGooglePlacesAutoComplete";
import { useFormikContext, FormikValues, FieldArray } from "formik";
import { useMemo, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { FaDotCircle, FaRegCircle } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import {
  selectLastMilePricing,
  selectInterStatePricing,
} from "redux/slices/adminSlices/settingsSlice";
import { selectClientProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";
import { IOrderType } from "types/global";
import { DELIVERY_OPTIONS, PARCEL_TYPE_OPTIONS } from "utils/constants";
import { isClientLoggedIn } from "utils/utilFunctions";
import { ImBin } from "react-icons/im";
import { LocationIcon, Profile3Icon } from "utils/customIcons";

function ClientCreateOrderForm() {
  const [searchParams] = useSearchParams();
  const orderType = useMemo(
    () => searchParams.get("type") || "lastMile",
    [searchParams]
  ) as IOrderType;

  const clientProfile = useAppSelector(selectClientProfile);

  const lastMilePricing = useAppSelector(selectLastMilePricing);
  const interStatePricing = useAppSelector(selectInterStatePricing);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    isSubmitting,
    setValues,
  } = useFormikContext<FormikValues>();

  const deleteParcelDisclosure = useDisclosure();
  const [parcelToDeleteIndex, setParcelToDeleteIndex] = useState<number | null>(null);

  function handleDeleteParcel() {
    if (parcelToDeleteIndex >= 0) {
      const newArr = values.parcelDetails.filter((_, index) => index !== parcelToDeleteIndex);

      setFieldValue("parcelDetails", newArr);
    }

    deleteParcelDisclosure.onClose();
  }

  function handleUseAccountInfo(checked: boolean) {
    if (checked) {
      setValues({
        ...values,
        customerName: clientProfile?.fullName || "",
        customerPhoneNumber: clientProfile?.phoneNumber || "",
      });
    } else {
      setValues({
        ...values,
        customerName: "",
        customerPhoneNumber: "",
      });
    }
  }

  return (
    <WhiteBox px={0} py={0}>
      <Flex flexDir="column" gap={6} maxW="1000px">
        <Box>
          <Text color="neutral.500">
            Select {/* {orderType === "lastMile" ? "last mile" : "inter state"} */} delivery type
          </Text>

          <Wrap mt={4}>
            {(orderType === "lastMile" ? lastMilePricing : interStatePricing).map((item, key) => (
              <Center
                flexDir="column"
                gap={2}
                px="14px"
                py="10px"
                maxW="100px"
                w="full"
                key={key}
                border="1px solid #0600891A"
                borderRadius="4px"
                cursor="pointer"
                transition="all 200ms ease"
                position="relative"
                onClick={() => setFieldValue("pricingId", item?._id)}
                _hover={{ bgColor: "#0600891A" }}
              >
                {/* Floating Radio Icon */}
                <Icon
                  as={values.pricingId === item?._id ? FaDotCircle : FaRegCircle}
                  boxSize={4}
                  pos="absolute"
                  top="5px"
                  right="5px"
                  color="primary.500"
                />

                {/* Vehicle Icon */}
                <Icon
                  as={DELIVERY_OPTIONS.find(i => i.value === item?.deliveryType)?.icon}
                  boxSize={8}
                />

                <Text fontSize="10px" fontWeight={300} textAlign="center">
                  {item?.name}
                </Text>
              </Center>
            ))}
          </Wrap>
          {errors.pricingId && <SDInputErrorMessage error={errors.pricingId} />}
        </Box>

        <Flex flexDir="column" gap={6} maxW="480px">
          <Box>
            <Heading fontWeight={600} fontSize={{ base: "16px", md: "20px" }} mb={4}>
              Delivery Details
            </Heading>

            <SDVerticalStepper
              stepIndicatorStyles={{ backgroundColor: "transparent" }}
              stepSeparatorStyles={{ backgroundColor: "#063496" }}
              steps={[
                {
                  title: "",
                  description: (
                    <SDGooglePlacesAutoComplete
                      id="pickupLocation"
                      label="Pick-Up Location"
                      placeholder="Search for a location"
                      value={values.pickupLocation}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      error={touched.pickupLocation && errors.pickupLocation}
                      containerProps={{ mt: 0 }}
                    />
                  ),
                  icon: LocationIcon,
                },
                {
                  title: "",
                  description: (
                    <SDGooglePlacesAutoComplete
                      id="dropOffLocation"
                      label="Drop-off Location"
                      placeholder="Search for a location"
                      value={values.dropOffLocation}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      error={touched.dropOffLocation && errors.dropOffLocation}
                      containerProps={{ mt: 0 }}
                    />
                  ),
                  icon: Profile3Icon,
                },
              ]}
            />
          </Box>

          <Box>
            <Heading fontWeight={600} fontSize={{ base: "16px", md: "20px" }}>
              Sender’s information
            </Heading>

            {isClientLoggedIn() && (
              <Checkbox
                mt={4}
                colorScheme="primary"
                onChange={e => handleUseAccountInfo(e.target.checked)}
                defaultChecked
                size="sm"
              >
                <Text fontSize="16px">Use my account information</Text>
              </Checkbox>
            )}

            <SDInput
              id="customerName"
              type="text"
              label="Name"
              placeholder="Enter full name"
              value={values.customerName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.customerName && errors.customerName}
            />

            <SDInput
              id="customerPhoneNumber"
              type="tel"
              label="Phone Number"
              placeholder="Enter phone number"
              value={values.customerPhoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.customerPhoneNumber && errors.customerPhoneNumber}
            />

            <SDInput
              id="customerPickupCode"
              type="text"
              label="Pick-Up Code"
              placeholder=""
              defaultValue={values.customerPickupCode}
              onBlur={handleBlur}
              error={touched.customerPickupCode && errors.customerPickupCode}
              isReadOnly={true}
            />
          </Box>

          <Box>
            <Heading fontWeight={600} fontSize={{ base: "16px", md: "20px" }}>
              Receiver’s information
            </Heading>

            <SDInput
              id="recipientName"
              type="text"
              label="Name"
              placeholder="Enter full name"
              value={values.recipientName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.recipientName && errors.recipientName}
            />

            <SDInput
              id="recipientPhoneNumber"
              type="tel"
              label="Phone Number"
              placeholder="Enter phone number"
              value={values.recipientPhoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.recipientPhoneNumber && errors.recipientPhoneNumber}
            />

            <SDInput
              id="recipientDropOffCode"
              type="text"
              label="Drop-Off Code"
              placeholder=""
              defaultValue={values.recipientDropOffCode}
              onBlur={handleBlur}
              error={touched.recipientDropOffCode && errors.recipientDropOffCode}
              isReadOnly={true}
            />
          </Box>

          <Box>
            <Heading fontWeight={600} fontSize={{ base: "16px", md: "20px" }} mb={4}>
              What’s in the package?
            </Heading>

            <FieldArray name="parcelDetails">
              {({ push }) => {
                const lastIndex = values?.parcelDetails?.length - 1;

                return (
                  <>
                    <Wrap mb={4}>
                      {PARCEL_TYPE_OPTIONS?.map((item, key) => (
                        <Box
                          key={key}
                          cursor="pointer"
                          bgColor={
                            values?.parcelDetails[lastIndex]?.parcelType?.value === item?.value
                              ? "primary.500"
                              : "#07052912"
                          }
                          borderRadius="20px"
                          p="6px"
                          onClick={() => {
                            setFieldValue(`parcelDetails.${lastIndex}.content`, item?.label);
                            setFieldValue(`parcelDetails.${lastIndex}.parcelType`, item);
                          }}
                        >
                          <Text
                            fontSize="12px"
                            fontWeight={600}
                            lineHeight="22px"
                            color={
                              values?.parcelDetails[lastIndex]?.parcelType?.value === item?.value
                                ? "white"
                                : "primary.500"
                            }
                          >
                            {item?.label}
                          </Text>
                        </Box>
                      ))}
                    </Wrap>

                    <SDInput
                      id={`parcelDetails.${lastIndex}.noOfContents`}
                      type="number"
                      label="Quantity"
                      placeholder="0"
                      value={values?.parcelDetails[lastIndex]?.noOfContents}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.parcelDetails && errors.parcelDetails}
                    />

                    <SDButton
                      leftIcon={<BsPlusLg />}
                      colorScheme="secondary2"
                      variant="link"
                      size="sm"
                      p={0}
                      ps={1}
                      pe={2}
                      mt="10px"
                      mb={4}
                      isDisabled={
                        !values?.parcelDetails[lastIndex]?.content ||
                        !values?.parcelDetails[lastIndex]?.noOfContents ||
                        !values?.parcelDetails[lastIndex]?.parcelType
                      }
                      onClick={() => push({ content: "", noOfContents: "", parcelType: null })}
                    >
                      Add another package
                    </SDButton>

                    {values.parcelDetails.slice(0, lastIndex).map((item, key) => (
                      <Flex key={key} gap={3} my={2} align="center">
                        <Box
                          flex={1}
                          borderRadius={8}
                          p="10px"
                          boxShadow="0px 4px 20px 0px #DFDEF0C3"
                        >
                          <Heading fontSize="sm" fontWeight={500} mb={3}>
                            Package {key + 1}:
                          </Heading>
                          <Flex gap={4}>
                            <Text color="neutral.500" fontSize="xs">
                              Package: {item?.content}
                            </Text>
                            <Text color="neutral.500" fontSize="xs">
                              Quantity: {item?.noOfContents}
                            </Text>
                          </Flex>
                        </Box>

                        <IconButton
                          variant="ghost"
                          colorScheme="error"
                          onClick={() => {
                            setParcelToDeleteIndex(key);
                            deleteParcelDisclosure.onOpen();
                          }}
                          aria-label="Delete Parcel"
                          color="#FF0642"
                          icon={<ImBin />}
                          borderRadius="full"
                        />
                      </Flex>
                    ))}
                  </>
                );
              }}
            </FieldArray>
          </Box>

          <Center mt={8}>
            <SDButton isLoading={isSubmitting} colorScheme="primary" type="submit" w="full">
              Proceed
            </SDButton>
          </Center>
        </Flex>
      </Flex>

      <WarningModal
        isOpen={deleteParcelDisclosure.isOpen}
        onClose={deleteParcelDisclosure.onClose}
        message="Do you want to delete this parcel?"
        subMessage={values.parcelDetails[parcelToDeleteIndex]?.content}
        proceedBtnText="Delete"
        onBtnClick={() => handleDeleteParcel()}
      />
    </WhiteBox>
  );
}

export default ClientCreateOrderForm;
