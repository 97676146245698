import {
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
  TableProps,
  Grid,
  Center,
  Box,
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { IPaginationConfig } from "types/global";
import { EmptyState, SDPagination } from "..";

interface Props extends TableProps {
  isLoading?: boolean;

  size?: "sm";
  header: ITableHeader[];
  body: IBody[];

  actionBtnText?: string;
  onActionBtnClick?: () => void;

  onRowClick?: (row: IBody) => void;
  rowClassName?: string;

  paginationConfig?: IPaginationConfig;
  onPaginationChange?: (page?: number) => Promise<void>;
}

export interface ITableHeader {
  label: string;
  dataIndex: string;
  render?: (value: any, record: any, index: number) => React.ReactNode;
}

interface IBody {
  [dataIndex: string]: any;
}

function SDTable({
  isLoading,
  size,
  header,
  body,
  paginationConfig,
  onPaginationChange,
  actionBtnText,
  onActionBtnClick,
  onRowClick,
  rowClassName,
}: Props) {
  const ITEMS_PER_PAGE = Number(paginationConfig?.size) || 50;

  function getRowIndex(index: number) {
    const rowIndex = index + 1;

    const newIndex = rowIndex + (paginationConfig?.page - 1) * ITEMS_PER_PAGE - 1;

    return newIndex;
  }

  return (
    <TableContainer whiteSpace="break-spaces" pos="relative">
      {/* Do not remove */}
      <Box id="topOfTheTable" pos="absolute" top="-150px" zIndex={99999} />

      <Grid overflowX="auto">
        {isLoading && (
          <Box position="relative">
            <Center
              position="absolute"
              top={24}
              left={0}
              zIndex={4}
              w="full"
              h="full"
              maxH="400px"
              opacity={1}
              textAlign="center"
            >
              <BeatLoader loading={true} color="#0a2d79" size="20px" />
            </Center>
          </Box>
        )}
        <Table
          overflow="hidden"
          {...(isLoading ? { opacity: 0.3, userSelect: "none", pointerEvents: "none" } : {})}
        >
          <Thead bgColor="#07052912">
            <Tr>
              {header.map((item, key) => (
                <Th
                  key={key}
                  fontSize={size || "md"}
                  color="#000000"
                  textTransform="initial"
                  px={size === "sm" ? "14px" : "24px"}
                  py={size === "sm" ? "16px" : "26px"}
                  mb={6}
                  border={0}
                  _first={{ borderLeftRadius: "10px" }}
                  _last={{ borderRightRadius: "10px" }}
                >
                  {item.label}
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody>
            {body.length !== 0 ? (
              body.map((row, rowKey) => (
                <Tr
                  key={rowKey}
                  onClick={() => onRowClick && onRowClick(row)}
                  className={rowClassName}
                >
                  {header.map(({ dataIndex, render }, columnKey) => (
                    <Td
                      key={columnKey}
                      px={size === "sm" ? "14px" : "24px"}
                      py={size === "sm" ? "14px" : "24px"}
                      fontSize="sm"
                      color="neutral.200"
                    >
                      {render ? render(row[dataIndex], row, getRowIndex(rowKey)) : row[dataIndex]}
                    </Td>
                  ))}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td px="8px" py="24px" fontSize="sm" colSpan={header.length} textAlign="center">
                  <EmptyState actionBtnText={actionBtnText} onActionBtnClick={onActionBtnClick} />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Grid>

      {/* P A G I N A T I O N */}
      <SDPagination
        paginationConfig={paginationConfig}
        onPaginationChange={page => onPaginationChange(page)}
        sectionToSrollTo="topOfTheTable"
      />
    </TableContainer>
  );
}

export default SDTable;
