import { Tag } from "@chakra-ui/react";
import { startCase, lowerCase } from "lodash";

interface Props {
  children: string;
}

function SDTag({ children = "" }: Props) {
  let color = "";
  let bgColor = "";

  switch (lowerCase(children)) {
    // GREEN
    case "successful":
    case "assigned":
    case "delivered":
    case "paid":
    case "active":
      color = "success.500";
      bgColor = "#E6FDEEC3";
      break;

    // YELLOW
    case "pending":
    case "scheduled":
    case "unassigned":
      color = "#e2b631";
      bgColor = "#FDF3D8C5";
      break;

    // RED
    case "rejected":
    case "cancelled":
    case "failed":
    case "deactivated":
      color = "error.500";
      bgColor = "#F7D8DFC5";
      break;

    // BLUE
    case "settled":
    case "accepted":
      color = "#0F3CD9";
      bgColor = "#0F3CD91A";
      break;

    // GREY
    case "in transit":
    case "returning":
    case "verified":
    case "picked up":
      color = "neutral.500";
      bgColor = "#07052912";
      break;

    // YELLOW (DEFAULT)
    default:
      color = "#e2b631";
      bgColor = "#FDF3D8C5";
      break;
  }

  return (
    <Tag
      color={color}
      bgColor={bgColor}
      variant="subtle"
      fontWeight={600}
      size="sm"
      whiteSpace="nowrap"
    >
      {typeof children === "string" ? startCase(children) : children}
    </Tag>
  );
}

export default SDTag;
