import { Search2Icon, SearchIcon } from "@chakra-ui/icons";
import { Avatar, Center, Flex, HStack, IconButton, Text, useDisclosure } from "@chakra-ui/react";
import {
  ConfirmModal,
  EmptyState,
  SDButton,
  SDInput,
  SDModal,
  SDTag,
  SuccessModal,
} from "components/global";
import { useEffect, useState } from "react";
import { FaRegCircle, FaCheckCircle } from "react-icons/fa";
import { GoCircleSlash } from "react-icons/go";
import { BeatLoader } from "react-spinners";
import { selectCouriers } from "redux/slices/adminSlices/courierSlice";
import { ICourier } from "redux/slices/adminSlices/courierSlice/types";
import { IShipment } from "redux/slices/adminSlices/shipmentSlice/types";
import { useAppSelector } from "redux/store";
import { handleFetchCouriers } from "services/admin/courier";
import { handleProviderAssignOrder } from "services/admin/shimpent";
import { VanIcon } from "utils/customIcons";
import { useNavigate } from "react-router-dom";
import { AllRoutes } from "routes/AllRoutes";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  shipmentToAssign: IShipment;
}

function AssignCourierModal({ isOpen, onClose, shipmentToAssign }: Props) {
  const navigate = useNavigate();

  const couriersState = useAppSelector(selectCouriers);

  const [isLoading, setIsLoading] = useState(false);
  const [isiAssigning, setIsiAssigning] = useState(false);
  const [courierToAssignTo, setCourierToAssignTo] = useState<ICourier>(null);

  const [searchValue, setSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const confirmAssignOrderDisclosure = useDisclosure();
  const assignSuccessDisclosure = useDisclosure();

  async function handleAssign() {
    setIsiAssigning(true);

    await handleProviderAssignOrder(shipmentToAssign?._id, courierToAssignTo?._id, () => {
      onClose();
      assignSuccessDisclosure.onOpen();
    });

    setIsiAssigning(false);
  }

  async function handleSearch(e: any, page: number = 1) {
    e?.preventDefault();

    setIsSearching(true);

    await handleFetchCouriers(page, 20, searchValue.trim());

    setIsSearching(false);
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      await handleFetchCouriers();

      setIsLoading(false);
    }

    if (!searchValue.trim()) fetchData();
  }, [searchValue]);

  //   Reset selected courier
  useEffect(() => {
    setCourierToAssignTo(null);
  }, [isOpen]);

  return (
    <>
      <SDModal isOpen={isOpen} onClose={onClose} title="Select a Courier" size="lg">
        {isLoading && couriersState?.records?.length === 0 ? (
          <Center p={4}>
            <BeatLoader loading={true} color="#0a2d79" size="20px" />
          </Center>
        ) : couriersState?.records?.length === 0 && !searchValue ? (
          <EmptyState
            title="You have not added any courier"
            message="Click on the button below to add a new courier"
            actionBtnText="Add Courier"
            onActionBtnClick={() => navigate(AllRoutes.providerAddCourier)}
          />
        ) : (
          <Flex flexDir="column" gap={8}>
            <form onSubmit={handleSearch}>
              <HStack>
                <SDInput
                  placeholder="Search by name or phone number"
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                  addOnElementAfter={
                    !!searchValue.trim() ? (
                      <IconButton
                        type="submit"
                        icon={<Search2Icon w="16px" h="16px" />}
                        variant="solid"
                        colorScheme="primary"
                        aria-label="Search"
                        isLoading={isSearching}
                        borderStartRadius={0}
                        size="lg"
                      />
                    ) : (
                      <SearchIcon />
                    )
                  }
                  containerProps={{ my: 0 }}
                />
              </HStack>
            </form>

            <Flex flexDir="column" gap={6} flex={1} overflowY="auto" maxH="50vh">
              {couriersState.records.map((courier, key) => (
                <Flex
                  key={key}
                  gap={6}
                  align="center"
                  justify="space-between"
                  cursor={courier?.isActive ? "pointer" : "not-allowed"}
                  borderRadius={6}
                  px={2}
                  py={2}
                  opacity={courier?.isActive ? 1 : 0.5}
                  transition="all 100ms ease-in-out"
                  _hover={{ bgColor: "rgba(7, 5, 41, 0.07)" }}
                  onClick={() => (courier?.isActive ? setCourierToAssignTo(courier) : null)}
                >
                  <Flex align="center" gap={3} flex={1}>
                    <Avatar name={courier?.fullName} src={courier?.profilePicture} />

                    <Flex flexDir="column">
                      <Text fontWeight={700} color="neutral.900">
                        {courier?.fullName}
                      </Text>
                      <HStack>
                        <VanIcon />
                        <Text fontWeight={500} color="#656566">
                          {courier?.phoneNumber}
                        </Text>
                      </HStack>
                    </Flex>
                  </Flex>

                  <SDTag>{courier?.isActive ? "Active" : "Deactivated"}</SDTag>

                  {courierToAssignTo?._id === courier?._id ? (
                    <FaCheckCircle />
                  ) : !courier?.isActive ? (
                    <GoCircleSlash />
                  ) : (
                    <FaRegCircle />
                  )}
                </Flex>
              ))}
              {/* TODO: Add pagination here */}
            </Flex>

            {couriersState.records.length !== 0 && (
              <SDButton
                colorScheme="primary"
                w="full"
                isDisabled={!courierToAssignTo}
                isLoading={isiAssigning}
                onClick={confirmAssignOrderDisclosure.onOpen}
              >
                Confirm
              </SDButton>
            )}
          </Flex>
        )}
      </SDModal>

      <ConfirmModal
        isOpen={confirmAssignOrderDisclosure.isOpen}
        onClose={confirmAssignOrderDisclosure.onClose}
        message="Are you sure you want to assign this order to this courier?"
        subMessage={courierToAssignTo?.fullName}
        onBtnClick={() => {
          handleAssign();
          confirmAssignOrderDisclosure.onClose();
        }}
        isLoading={false}
      />

      <SuccessModal
        isOpen={assignSuccessDisclosure.isOpen}
        onClose={assignSuccessDisclosure.onClose}
        message="Order assigned successfully."
      />
    </>
  );
}

export default AssignCourierModal;
